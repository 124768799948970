<template>
  <div>
    <CSpinner v-if="$apollo.queries.product.loading" color="info"/>
    <CAlert
        v-if="error"
        color="danger"
        closeButton
        @update:show="removeError">
      {{error}}
    </CAlert>
    <DeleteModal
        entity="product"
        :name="itemName"
        :nid="product.nid"
        :show.sync="showDeleteItemModal"
        v-on:delete="deleteItem"
        v-on:cancel-delete="toggleDeleteItemModal"
    />
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol class="text-left" sm="2">
            <DetailsButtonGroup
                v-on:save="saveItem"
                v-on:cancel="resetItem"
                v-on:delete-prompt="toggleDeleteItemModal"
                v-on:close="$router.push({path: '/contracts/admin/products'})"
            />
          </CCol>
          <CCol sm="8">
            <h2>{{itemName}}</h2>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <ProductForm
            v-bind:productIn="productEdited"
            v-on:update-item="updateItem"
            v-on:error="catchError"
        />
      </CCardBody>
      <CCardFooter
          class="text-left">
        <DetailsButtonGroup
            v-on:save="saveItem"
            v-on:cancel="resetItem"
            v-on:delete-prompt="toggleDeleteItemModal"
            v-on:close="$router.push({ path: '/contracts/admin/products'})"
        />
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import gql from "graphql-tag";
import DetailsButtonGroup from "@/components/common/DetailsButtonGroup";
import DeleteModal from "@/components/common/DeleteModal";
import ProductForm from "@/apps/contracts/components/ProductForm";

const queryProduct = gql`
query product($nid: ID!) {
  product(nid: $nid) {
    nid
    entity
    name
    description
    brandingName
    standardPricePerItem
    productGroup {
      nid
      name
    }
    billingArticle {
      nid
      name
    }
  }
}
`
const updateProduct = gql`
  mutation updateProduct($nid: ID!, $product: ProductInput!){
    updateProduct(nid: $nid, product: $product){
        nid
    }
  }
`
const deleteProduct = gql`
  mutation deleteProduct($nid: ID!){
    deleteProduct(nid: $nid)
  }
`
const clonedeep = require('lodash.clonedeep')

export default {
  name: "Product",
  components: {
    DetailsButtonGroup,
    DeleteModal,
    ProductForm
  },
  data() {
    return {
      error: null,
      showDeleteItemModal: false,
      product: {},
      productEdited: {},
      productSave: {}
    }
  },
  methods: {
    removeError(hide){
      if(!hide){
        this.error = null
      }
    },
    catchError(event){
      this.error = event
    },
    updateItem(data){
      this.productSave = data
    },
    saveItem(){
      const {nid, __typename, ...updatedObj} = this.productSave;
      this.$apollo.mutate({
        mutation: updateProduct,
        variables: {
          nid: nid,
          product: updatedObj
        }
      })
      .then(res => {
        this.$emit('save-item', this.productEdited);
        this.$store.commit('set', ['addModalShow', false]);
        this.$store.commit('set', ['refetchData', true]);
        this.$router.push({ path: '/contracts/admin/products'});
      })
      .catch(error => {
        console.error(error);
        this.error = error.message;
      })
    },
    toggleDeleteItemModal(){
      this.showDeleteItemModal = !this.showDeleteItemModal;
    },
    resetItem(){
      this.productEdited = clonedeep(this.$apolloData.data.product);
    },
    deleteItem(){
      this.$apollo.mutate({
        mutation: deleteProduct,
        variables: {
          nid: this.product.nid
        }
      })
      .then(res => {
        this.$emit('delete-product', this.product.nid);
        this.$store.commit('set', ['refetchData', true]);
        this.$router.push({ path: '/contracts/admin/products'});
      })
      .catch(error => {
        console.error(error);
        this.error = error.message;
      })
    }
  },
  computed: {
    allowEdit() {
      return this.$store.state.allowEdit
    },
    itemName(){
      return this.productEdited.name
    }
  },
  apollo: {
    product: {
      query: queryProduct,
      error(error) {
        this.error = error.message;
      },
      variables() {
        return {
          nid: this.$route.params.nid
        }
      },
      result ({ data, loading, networkStatus }) {
        this.resetItem();
      },
      fetchPolicy: "cache-and-network"
    }
  }
}
</script>

<style scoped>
.btn-validation{
 width: 100%;
}
</style>
