<template>
  <div>
    <CRow>
      <CCol sm="6">
        <CInput
            :is-valid="notNull"
            v-model="product.name"
            v-on:change="updateItem"
            :readonly="!allowEdit"
            description="Product name"
        />
      </CCol>
      <CCol sm="4">
        <multiselect
            :disabled="!allowEdit"
            v-model="product.productGroup"
            @input="updateItem"
            :options="this.productGroups ? this.productGroups.data: []"
            :show-labels="true"
            label="name"
            track-by="name"
            :searchable="true"
            :close-on-select="true"
            :allow-empty="false"
            select-label="Select"
            deselect-label="Required field"
            placeholder="Select product group"
          />
          <CRow>
            <small class="form-text text-muted w-100">Product group</small>
          </CRow>
      </CCol>
      <CCol sm="2">
        <multiselect
            data-sel="slct-enty"
            :disabled="!allowEdit"
            v-model="product.entity"
            @input="updateItem"
            :options="Object.keys(this.entityOptions)"
            :custom-label="(item) => this.entityOptions[item]"
            :searchable="true"
            :close-on-select="true"
            :allow-empty="false"
            placeholder="Select Entity"
            select-label="Select"
            deselect-label="Required field"
        />
        <CRow>
          <small class="form-text text-muted w-100">Entity</small>
        </CRow>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="4">
        <CInput
            v-model="product.brandingName"
            v-on:change="updateItem"
            :readonly="!allowEdit"
            description="Branding name"
            @input="product.brandingName = $event !== '' ? $event : null"
        />
      </CCol>
      <CCol sm="4">
        <multiselect
            :disabled="!allowEdit"
            v-model="product.billingArticle"
            @input="updateItem"
            :options="this.billingArticles ? this.billingArticles.data? this.billingArticles.data: []: []"
            :show-labels="true"
            label="name"
            track-by="name"
            :searchable="true"
            :close-on-select="true"
            :allow-empty="true"
            select-label="Select"
            deselect-label="Remove"
            placeholder="Select billing article"
            v-on:search-change="updateBillingArticleQ"
          />
          <CRow>
            <small class="form-text text-muted w-100">Billing Article</small>
          </CRow>
      </CCol>
      <CCol sm="4">
        <CInput
          type="number"
          @wheel="$event.target.blur()"
          step="0.01"
          v-model="product.standardPricePerItem"
          v-on:update:value="updateItem"
          append="€"
          :readonly="!allowEdit"
          description="Price per item"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="4">
        <CTextarea
            rows="5"
            v-model="product.description"
            v-on:change="updateItem"
            :readonly="!allowEdit"
            placeholder="Add description"
            @input="product.description = $event !== '' ? $event : null"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import gql from "graphql-tag";
const clonedeep = require('lodash.clonedeep')

const queryProductGroups = gql`
query {
  productGroups(order: "name") {
    data{
      nid
      name
    }
   }
}
`

const queryBillingArticles = gql`
query billingArticles($q: String){
  billingArticles(q: $q, order: "name") {
    data{
      nid
      name
    }
  }
}
`

export default {
  name: "ProductForm",
  props: ["productIn"],
  data(){
    return {
      productGroups: [],
      billingArticles: [],
      billingArticleQ: null,
      entityOptions: {
        MS: 'MS Media',
        PW: 'PW Internet',
        IM: 'Immowelt',
      },
    }
  },
  methods: {
    updateItem(){
      const data = clonedeep(this.product)
      //convert numbers
      data.standardPricePerItem = this.product.standardPricePerItem? parseFloat(this.product.standardPricePerItem): null;
      //multiselect object
      data.productGroup = this.product.productGroup? {nid: this.product.productGroup.nid}: null;
      data.billingArticle = this.product.billingArticle? {nid: this.product.billingArticle.nid}: null;
      this.$emit('update-item', data);
    },
    notNull(val) {
        return val !== undefined && val !== null && val !== ''
    },
    updateBillingArticleQ(event){
      this.billingArticleQ = event
    },
  },
  apollo: {
    productGroups: {
      query: queryProductGroups,
      error(error) {
        console.error(error);
        this.$emit("error", JSON.stringify(error));
      }
    },
    billingArticles: {
      query: queryBillingArticles,
      variables(){
        return {
          q: this.billingArticleQ
        }
      },
      error(error) {
        console.error(error);
        this.$emit("error", JSON.stringify(error));
      }
    }
  },
  computed: {
    product(){
      return this.productIn? this.productIn: {}
    },
    allowEdit: {
      get() {
        return this.$store.state.allowEdit
      },
      set (){
      }
    }
  },
  watch:{
    productIn(){
      // reset enum selects
      this.updateItem();
    }
  }
}
</script>

<style scoped>
 .multiselect {
   border-width: 2pt;
   ;
   list-style-type: none;
   margin-bottom: 1pt;
 }
 .ms-row {
   margin-bottom: .75em;
 }
</style>
